<template>
  <v-app id="404">
    <v-container fluid fill-height class="not-found-wrapper">
      <v-layout align-center justify-center class="not-found-error">
        <div class="text-md-center">
          <div>
            <img class="not-found-error__icon" src="/static/error/404-illustration-error.svg" />
          </div>
          <div class="not-found-error__title mt-6 mb-2">
            {{ $t('errors.not_found_title') }}
          </div>
          <div class="not-found-error__message">
            {{ $t('errors.not_found_message') }}
          </div>
          <div v-if="showGoHomeButton" class="mt-8">
            <button class="not-found-error__button" @click="goHome">
              {{ $t('common.go_home') }}
            </button>
          </div>
        </div>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import { baseNameRoutes } from '@/router/paths';

export default {
  data() {
    return {
      showGoHomeButton: true,
    };
  },
  methods: {
    goHome() {
      this.$router.push({ name: baseNameRoutes.login });
    },
  },
};
</script>
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.not-found-wrapper {
  background-color: #f7f9fc;
}

.not-found-error {
  &__icon {
    height: auto;
    max-width: 364px;
  }

  &__title {
    color: #202842;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 600;
  }

  &__message {
    color: #556575;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    max-width: 453px;
  }

  &__button {
    background-color: var(--v-primary-base);
    border-radius: 4px;
    color: white;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 23.4px;
    outline: none;
    padding: 12px 31px;

    &:hover,
    &:active {
      background-color: var(--v-primary-darken1);
    }
  }
}
</style>
